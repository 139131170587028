/* Main navigation
========================================================================== */
header{
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1500;
  transform: translateY(0);
  transition: transform 250ms ease-in-out, color 250ms ease-in-out;
  padding: 1rem 2rem;
  .header-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.logo{
  width: 100px;
  height: auto;
  // @include breakpoint(sm){
  //   width: 120px;
  // }
}

.header-title{
  display: none;
  @include breakpoint(sm){
    display: block;
  }
  p{
    padding: 0;
    vertical-align: middle;
  }
}

.header-bg{
  position: fixed;
  width: 100%;
  height: 63px;
  top: 0;
  z-index: 1000;
  background-color: $tan;
}

header.hidden{
  transform: translateY(-100px);
}

.hamburger .line{
  width: 40px;
  height: 1px;
  display: block;
  margin: 10px auto;
  transition: all 0.1s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}

// Top bar
.hamburger.is-active .line:nth-child(1){
  transform: translateY(11px) rotate(45deg);
  animation: tbar .25s cubic-bezier(0.5, 0.2, 0.2, 1.01);
}

// Middle bar
.hamburger.is-active .line:nth-child(2){
  opacity: 0;
}

// Bottom bar
.hamburger.is-active .line:nth-child(3){
  transform: translateY(-11px) rotate(-45deg);
  animation: bbar .25s cubic-bezier(0.5, 0.2, 0.2, 1.01);
}

// Animation
@keyframes tbar {
  0% {
    transform: translateY(0px) rotate(0);
  }
  100% {
    transform: translateY(11px) rotate(45deg);
  }
}

@keyframes bbar {
  0% {
    transform: translateY(0px) rotate(0);
  }
  100% {
    transform: translateY(-11px) rotate(-45deg);
  }
}



// .menu-item{
//   opacity: 1;
//   transition: all 500ms ease-in-out;
//   margin-top: 0.5rem;
//   &.is-active{
//     opacity: 1;
//     margin-top: 0;
//   }
// }

// .menu:hover li:not(:hover) {
//   opacity: 0.25;
// }

.off-canvas-menu{
  position: fixed;
  width: 100%;
  height: calc(100vh);
  // right: 0;
  transform: translateX(100vw);
  z-index: 1001;
  transition: transform 0.85s $ease;
  background-color: $mustard;
  color: inherit;
  // padding-top: 100px;
  // visibility: hidden;
  top: 0;
  &.is-active{
    transform: translateX(0);
    // visibility: visible;
    @include breakpoint(sm){
      transform: translateX(50vw);
    }
    .menu{
      opacity: 1;
    }
    .social{
      opacity: 1;
      transform: translateY(0);
    }
  }
  @include breakpoint(sm){
    width: calc(50vw);
  }
}

.menu{
  // margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  opacity: 1;
  // opacity: 0;
  // transition: opacity 0.45s ease-in 0.85s;
}


.social{
  opacity: 1;
  // opacity: 0;
  // transition: all 0.45s ease-in 0.55s;
  // transform: translateY(20px);
}
