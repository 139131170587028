/* General layout
========================================================================== */
.page-green{
  background-color: $green;
}

.page-tan{
  background-color: $tan;
}

.page-title{
  position: fixed;
  text-align: center;
  top: 20px;
  z-index: 1100;
  width: 100%;
}

.header-wrapper{
  border-bottom: 1px solid $black;
  .logo{
    fill: $black;
  }
}
.hamburger .line{
  background-color: $black;
}



main{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100%);
  position: relative;
}

footer{
  width: 100%;
  height: 500px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  max-width: 1440px;
  @include breakpoint(sm) {
    width: 100%;
    padding-left: 50px;
  }
}

/* Sections
========================================================================== */
// .section-intro{
//   display: flex;
//   flex-direction: row;
//   .intro-text{
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-content: center;
//     min-height: 100%;
//     margin-top: 65px;
//     // width: 100%;
//     // text-align: center;
//
//     display: flex;
//     justify-content: space-between;
//     flex-direction: column;
//     h2{
//       max-width: 40ch;
//       &:not(:first-child){
//         text-indent: 4rem;
//         padding-bottom: 4rem;
//       }
//     }
//     p{
//       max-width: 55ch;
//     }
//   }
//   .image{
//     height: 100vh;
//   }
// }

.section-intro{
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  .image{
    height: 100vh;
  }
}

.intro-text{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  min-height: 100%;
  // margin-top: 65px;
  width: 100%;
  text-align: center;

  @include breakpoint(md){
    width: 50%;
  }
  div:first-child{
    h2{
      max-width: 50ch;
      &:not(:first-child){
        text-indent: 4rem;
        padding-bottom: 4rem;
      }
    }
  }
}

.intro-headline{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 2;
  max-width: 45ch;
  margin: 0 auto;
  // opacity: 0;
}

.intro-description{
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  p{
    max-width: 65ch;
    padding: 0 3rem 3rem 3rem;
    margin: 0 auto;
  }
  p:last-child{
    padding-bottom: 5rem;
  }
  @include breakpoint(md){
    width: 50%;
  }
}


// .intro-description{
//   position: fixed;
//   bottom: 0;
// }

.intro-link{
  position: absolute;
  bottom: 0;
  width: 50%;
  padding-bottom: 2rem;
}

.intro-image{
  display: none;
  width: 50%;
  @include breakpoint(md){
    display: block;
  }
}

/* Section - Producers
========================================================================== */

.section-producers{
  // margin-top: 65px;
  position: relative;
}

.section-producers::after {
  content: '';
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 15vh;
  background: linear-gradient(
    rgba(234, 226, 207, 0),
    #eae2cf
  ); /* transparent keyword is broken in Safari */
  pointer-events: none;
}

.section-producers-overflow{
  overflow-y: scroll;
}

.section-producers-wrapper{
  padding: 150px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  @include breakpoint(md) {
    // flex-flow: column wrap;
    // max-height: 75vh;
  }
}

/* Section - Wines
========================================================================== */
.section-wines{
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  .wine-list-wrapper{
    margin-top: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    @include breakpoint(md) {
      width: 50%;
    }
    .wine-list{
      li{
        cursor: pointer;
        transition: all 500ms $ease;
        &:hover{
          color: $black;
        }
      }
    }
  }
}

.wine-link{
  top: -5rem;
  position: relative;
}

.wine-list-container{
  overflow-y: auto;
}

.intro-image{
  height: 100vh;
  background-color: rgb(238, 234, 225);
  display: none;
  width: 100%;
  pointer-events: none;
  @include breakpoint(md) {
    display: block;
    width: 50%;
  }
}
.intro-image-wrapper{
  padding-top: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  img{
    max-width: 60%;
  }
}

.wine-mobile-image{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  img{
    max-width: 40%;
  }
  @include breakpoint(md) {
    display: none;
    img{
      max-width: 60%;
    }
  }
}

.wine{
  cursor: pointer;
  transition: opacity .25s ease-in;
  &:not(:first-child){
    margin-top: -1rem;
  }
  &:nth-child(n+6) {
    display: none;
  }
}

.wine-list:hover .wine:not(:hover){
  opacity: 0.5;
}

.wine:hover{
  .info-toggle{
    // display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: all 0.25s ease-in-out;
  }
  .wine-image{
    opacity: 1;
  }
}

.wine.is-active{
  .info-toggle{
    opacity: 1;
    visibility: visible;
  }
  .wine-image{
    opacity: 1;
  }
}

.wine-title,
.wine-variety{
  transition: all 0.45s ease-in-out;
}

.wine-variety{
  font-style: italic;}

.wine-details{
  max-width: 90%;
  margin: 0 auto;
  @include breakpoint(md) {
    max-width: 75%;
  }
  // opacity: 0;
  // visibility: hidden;
  // height: 0;
  // display: none;
  // transition: opacity 0.45s ease-in-out, height 0.45s ease-in-out;
  // transition: all 0.45s ease-in;
}

// .wine-details.is-active {
//   opacity: 1;
//   visibility: visible;
//   height: auto;
//   padding: 2rem;
// }

.wine.not-active{
  opacity: 0;
  visibility: hidden;
  height: 0;
}


.wine-image{
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 50%;
  opacity: 0;
  z-index: 400;
  display: none;
  pointer-events: none;
  @include breakpoint(md) {
    display: block;
  }
  .image{
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}

.holding-image{
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.info-toggle{
  opacity: 0;
  visibility: hidden;
  // display: none;
  transition: opacity 0.25s ease-in;
  transform: translateY(10px);
}
//
// .section-information{
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-content: center;
//   // padding-top: 65px;
// }

.section-information{
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  .producer-profile{
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 65px;
    text-align: center;
    overflow-y: scroll;

    @include breakpoint(md){
      width: 50%;
    }
    p{
      margin: 0 auto;
      max-width: 50ch;
    }
  }
}

.producer-profile-image{
  height: 100vh;
  background-color: $tan;
  display: none;
  width: 100%;
  position: sticky;
  top: 0;
  @include breakpoint(md) {
    display: block;
    width: 50%;
  }
}
.producer-profile-image-wrapper{
  // padding-top: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  img{
    max-width: 60%;
  }
}

.producer-profile-mobile-image{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  img{
    max-width: 40%;
  }
  @include breakpoint(md) {
    display: none;
    img{
      max-width: 60%;
    }
  }
}


// .profile-paragraphs{
//   height: 0;
//   opacity: 0;
//   visibility: hidden;
//   // transition: all 0.05s ease-in-out;
// }
//
// .profile-paragraphs.visible{
//   height: auto;
//   opacity: 1;
//   visibility: visible;
// }

.pagination-nav{
  position: absolute;
  bottom: 0;
  padding:3rem;
  div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}


.heading-sticky{
  position: sticky;
  top: 50px;
  background: $white;
  z-index: 250;
  @include breakpoint(sm){
    top: 69px;
  }
}

/* Section — Contact
========================================================================== */
.contact{
  position: relative;
  width: 100%;
  bottom: 5rem;
  .contact-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-bottom: 5rem;
    .contact-enquiries{
      display: flex;
      flex-direction: row;
      justify-content: center;
      .contact-name{
        padding: 0 3rem;
      }
    }
  }
}

.section-contact{
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  .intro-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    min-height: 100%;
    margin-top: 65px;
    width: 100%;
    text-align: center;

    @include breakpoint(md){
      width: 50%;
    }
    div:first-child{
      h2{
        max-width: 50ch;
        &:not(:first-child){
          text-indent: 4rem;
          padding-bottom: 4rem;
        }
      }
    }
  }
}

.about{
  margin: 0 auto;
  max-width: 100%;
  hyphens: auto;
  max-width: 60ch;
  // text-align: center;
}

.section-social{
  position: fixed;
  bottom: 0;
  width: 100%;
  .social-links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    @include breakpoint(md) {
      flex-direction: row;
    }
  }
}

.accordion-header {
  cursor: pointer;
  span{
    transition: all 500ms $ease;
  }
  span:last-child::before{
    content: url(../icons/icon-plus.svg)
  }
  &.active{
    span:last-child{
      transform: rotate(45deg);
    }
  }
}

.accordion-content {
    display: none;
}

/* Background colours
========================================================================== */
.bg-white{
  background-color: $white;
}

.bg-tan{
  background-color: $tan;
}

.bg-mustard{
  background-color: $mustard;
}

.bg-brown{
  background-color: $brown;
}

.bg-green{
  background-color: $green;
}


/* Images
========================================================================== */

figure,
img{
  width: 100%;
  height: auto;
  display: block;
}

figure{
  margin: 0;
}

.image{
  position: absolute;
  top: 0;
  left: 0;
}


.sizer{
  position: relative;
  height: 100vh;
  background-color: rgb(238, 234, 225);
}

// .aspect-ratio{
//   background-color: #9B9B9B;
// }

.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity .5s ease-in-out;
}


/* Animation
========================================================================== */
.animate-in {
    -webkit-animation: fadeIn 1s ease-in;
    animation: fadeIn 1s ease-in;
}
.animate-out {
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
    opacity: 0;
}
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut{
    from { opacity: 1; }
    to { opacity: 0; }
}

.fade{
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: opacity 1600ms ease, transform 1600ms ease;
  transition-delay: 300ms;
}
.fade.fade-in{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.transition-fade {
  transition: .4s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}

/* Section: Wine list
========================================================================== */
.section-wine-list{
  // margin-top: 65px;
  position: relative;
}

.section-wine-list::after {
  content: '';
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 15vh;
  background: linear-gradient(
    rgba(234, 226, 207, 0),
    #eae2cf
  ); /* transparent keyword is broken in Safari */
  pointer-events: none;
}

.section-wine-list-overflow{
  overflow-y: scroll;
}

.section-wine-list-wrapper{
  padding: 150px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  @include breakpoint(md) {
    // flex-flow: column wrap;
    // max-height: 75vh;
  }
}
.wine-list-table-row-header{
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $black;
  padding: 1rem 0;
  width: 100%;
  @include breakpoint(md){
    flex-direction: row;
    flex-wrap:nowrap;
  }
}

.wine-list-table-row{
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $black;
  padding: 1rem 0 0 0;
  width: 100%;
  @include breakpoint(md){
    flex-direction: row;
  }
}
.wine-filters{
  position: sticky;
  top: 62px;
  background-color: $tan;
  z-index: 120;
  padding: 1rem 0;
  border-bottom: 1px solid $black;
  @include breakpoint(md){
    display: none;
  }
}

.wine-list-table-header{
  position: sticky;
  top: 102px;
  transform: translateY(-100vh);
  background-color: $tan;
  z-index: 100;
  transition: transform 0.45s $ease;
  &.is-active{
    transform: translateY(0);
  }
  @include breakpoint(md){
    transform: translateY(0);
    position: sticky;
    top: 62px;
  }
  .table-row-item{
    padding-bottom: .5rem;
    cursor: pointer;
    @include breakpoint(md){
      padding-bottom: 0;
    }
    &::after{
      content: '';
      display: inline-block;
      float: right;
      border-radius: 50%;
      vertical-align: middle;
      margin-top: 5px;
      margin-bottom: 0px;
      margin-left: 8px;
      width: 10px;
      height: 10px;
      // background: $black;
      border: 1px solid $black;
      @include breakpoint(md) {
        float: none;
        margin-top: 0px;
        margin-bottom: 2px;
      }
    }
    &:hover{
      &::after{
        background: $black;
      }
    }
  }
}

.table-row-item{
  min-width: calc(100% / 6);
  flex: 1;
  hyphens: auto;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.wine-filters{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include breakpoint(md) {
    display: none;
  }
}

.filter-mobile{
  // display: inline-block;
  @include breakpoint(md) {
    display: none;
  }
}

.filter-icon:after {
  content: '';
  display: inline-block;
  float: right;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 8px;
  width: 10px;
  height: 10px;
  border: 1px solid $black;
  &:hover{
    &::after{
      background: $black;
    }
  }
}

.filter-icon.is-active:after{
  background: $black;
}

.list-item > .wine-list-table-row{
  transition: opacity 0.25s ease-in;
}

.list:hover .list-item > .wine-list-table-row:hover{
  font-style: italic;
  cursor: pointer;
}

.list:hover .list-item > .wine-list-table-row:not(:hover) {
  // opacity: 0.35;
}

.table-row-item span:not(:last-of-type):after {
  content: ', ';
}

/* Search
========================================================================== */
.search-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    // margin-bottom: 8rem;
    position: relative;
}

input[type=search] {
    border: none;
    border-radius: 0;
    -webkit-appearance: none;

    background-color: $tan;
    height: 2.5em; /* for IE */
    vertical-align: middle; /* This is optional but it makes legacy IEs look better */
    text-align: center;
    font-family: $font-serif;
    font-size: 2rem;
    @include breakpoint(sm) {
      font-size: 2rem;
    }

    @include breakpoint(md) {
      font-size: 2.5rem;
    }

    @include breakpoint(lg)  {
      font-size: calc(2rem + 0.25vw);
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $black;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $black;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $black;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $black;
    }
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */


label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    font-family: $font-sans;
    background-color: $tan;
    font-size: inherit;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-out;
}

input:focus + label {
    opacity: 1;
    transform: translateY(-1rem);
}
