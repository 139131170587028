@charset 'utf-8';

@font-face {
  font-family: 'Domaine Text Light';
  font-weight: normal;
  font-style: normal;
  src: local('Domaine Text Light'),
       url('./fonts/DomaineText-Light.woff2') format('woff2'),
       url('./fonts/DomaineText-Light.woff') format('woff');
  }


// ==========================================================================
// $Base
// ==========================================================================
@import 'base/index';


// ::-webkit-scrollbar {
//   display: none;
// }

* {
  margin: 0;
  padding: 0;
  font: inherit;
  text-decoration: inherit;
  box-sizing: border-box;
  border: none;
  color: inherit;
  appearance: none;
  border-radius: 0;

  &:focus {
    outline: 0;
  }
}

/* Base Styles */
html {
  font-size: 62.5%; }
body {
  font-size: calc(1.8rem);
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  letter-spacing: 0.015em;
  word-spacing: 0.001em;
  line-height: 1.1em;
  font-weight: 400;
  font-family: $font-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  background-color: $tan;
  @include breakpoint(md){
    font-size: calc(2rem);
  }
}

// ==========================================================================
// $Components
// ==========================================================================

@import 'tachyons/index';
@import 'components/index';
